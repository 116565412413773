<template>
  <div>
    <b-form-checkbox
      @click.native="popupActive = true"
      value="1"
      unchecked-value="0"
      v-model="form.city_active"
      switch
      class="table-status-icon"
    />

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS' + params.data.city_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Location Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status ?</h5>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
              >
                Yes
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                No
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CountryService from "@/services/countryService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        city_active: "1",
      },
      copy_city_active: null,
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_city_active = this.form.city_active;
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodalS" + this.params.data.city_id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();
      let id = this.params.data.city_id;
      let payload = {
        city_id: this.form.city_id,
        city_name: this.form.city_name,
        country_id: this.form.country_id,
        city_active: this.form.city_active,
      };
      CountryService.updateCity(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActive = false;
            eventBus.$emit("refreshTableCity", data);
          } else {
            this.$vs.loading.close();
            this.privStatus();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.city_active = this.copy_city_active;
    },
  },
});
</script>
