<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full">
        <router-link to="/master">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <div
        v-if="
          permissionPageAccessArry.includes(PermissionVariables.SettingsAdd) ||
            loginUserRole == 'Admin'
        "
        class="vx-col w-full mb-base"
      >
        <CityForm />
      </div>

      <div class="vx-col w-full">
        <vs-tabs>
          <vs-tab
            v-if="
              permissionPageAccessArry.includes(
                PermissionVariables.SettingsAdd
              ) ||
                permissionPageAccessArry.includes(
                  PermissionVariables.SettingsView
                ) ||
                loginUserRole == 'Admin'
            "
            label="City List"
            icon-pack="feather"
            icon="icon-box"
          >
            <div class="tab-text">
              <div>
                <CityList />
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import CityForm from "@/views/apps/masters/city/CityForm.vue";
import CityList from "@/views/apps/masters/city/CityList.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";

export default {
  components: {
    CityForm,
    CityList,
  },

  data() {
    return {
      permissionPageAccessArry: localStorage.getItem("permissionPageAccess")
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
    };
  },
};
</script>
