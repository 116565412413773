<template>
  <vx-card>
    <div class="flex flex-wrap justify-between items-center form-title">
      <h4 class>City List</h4>
      <div class="flex flex-wrap justify-end items-center">
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
          <div class="sm:pr-2">
            <Multiselect
              class="mb-4 md:mb-0 mr-4"
              style="width: 100px"
              v-model="listType"
              :options="['Active', 'All']"
              @select="updateSelected"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :optionHeight="25"
              openDirection="bottom"
            />
          </div>

          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
        </div>
      </div>
    </div>
    <AgGridVue
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="listData"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :paginationPageSize="paginationPageSize"
      :pagination="true"
      :suppressPaginationPanel="true"
    />
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import Multiselect from "vue-multiselect/src/Multiselect";
import CityStatus from "./CityStatus.vue";
import CountryService from "@/services/countryService";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    CityStatus,
    Multiselect,
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getCityList({ page: val, type: this.listType });
          return val;
        } else return 1;
      },
    },
  },

  data() {
    return {
      listType: "Active",
      organization_id: null,
      totalPages: 0,
      listData: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      SelectOption: "Select Option",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
    };
  },

  methods: {
    updateSelected(val) {
      this.getCityList({ page: 1, type: String(val).toLowerCase() });
    },
    getCityList(filters = {}) {
      CountryService.getAllCity(filters)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.listData = data.data;
            this.totalPages = data.pagination.last_page;
            if (screen.width > 500) {
              this.gridApi.sizeColumnsToFit();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSearchQuery(val) {
      CountryService.getAllCity({ page: 1, search: val, type: this.listType })
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.listData = data.data;
            this.totalPages = data.pagination.last_page;
            if (screen.width > 500) {
              this.gridApi.sizeColumnsToFit();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  beforeMount() {
    eventBus.$on("refreshTableCity", (e) => {
      this.getCityList({ page: this.currentPage, type: this.listType });
    });
    this.organization_id = localStorage.getItem("OrganizationID");
    this.columnDefs = [
      {
        headerName: "City Name",
        field: "city_name",
        filter: false,
        width: 200,
      },
      {
        headerName: "Country",
        field: "country_name",
        filter: false,
        width: 200,
      },
      {
        headerName: "Status",
        field: "city_active",
        filter: false,
        width: 100,
        sortable: false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: CityStatus,
      },
    ];
  },

  mounted() {
    this.getCityList({ page: this.currentPage, type: this.listType });
    this.gridApi = this.gridOptions.api;
  },
};
</script>
