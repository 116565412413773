<template>
  <vx-card class="form-title" title="Add City">
    <form>
      <div class="vx-row">
        <div class="vx-col md:w-3/4 mx-auto">
          <div class="vx-row mb-6">
            <div
              class="vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"
            >
              <h6 class="mb-2 sm:mb-0">City Name*</h6>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <div class>
                <vs-input
                  v-validate="'required'"
                  name="set_name"
                  v-model="form.city_name"
                  class="w-full"
                  rules="alpha"
                />
              </div>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div
              class="vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"
            >
              <h6>Country*</h6>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <multiselect
                v-model="value"
                track-by="country_id"
                label="country_name"
                @input="({ country_id }) => (this.form.country_id = country_id)"
                :options="countries"
                name="Country"
                :searchable="true"
                :allow-empty="false"
                deselect-label="Can't remove this value"
                :clear-on-select="false"
                :preselect-first="true"
                open-direction="bottom"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{
                  option.country_name
                }}</template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-xs error-msg"
                  v-show="errors.has('Country')"
                  >{{ errors.first("Country") }}</span
                >
              </p>
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/5 w-full text-left sm:text-right">
              <h6 class="">Status</h6>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <b-form-checkbox
                value="1"
                unchecked-value="2"
                v-model="form.city_active"
                name="check-button"
                switch
              />
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col mx-auto">
          <vs-button
            :disabled="submitStatus"
            type="filled"
            @click.prevent="submitForm"
            class="mr-3 w-full sm:w-auto mb-3"
          >
            Submit
          </vs-button>

          <vs-button
            color="warning"
            type="border"
            class="w-full sm:w-auto mb-3"
            @click.prevent="clearForm"
          >
            Reset
          </vs-button>
        </div>
      </div>
    </form>
  </vx-card>
</template>

<script>
import CountryService from "@/services/countryService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  data() {
    return {
      submitStatus: true,
      form: {
        city_name: "",
        country_id: "",
        organization_id: localStorage.getItem("OrganizationID"),
        city_active: "1",
      },
      countries: [],
      value: [],
    };
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    getAllCountry: function() {
      CountryService.getAllCountry()
        .then((response) => {
          const { data } = response;
          if (data || data.Status == true) {
            this.countries = data.data;
          }
        })
        .catch((error) => {
          console.log("error:", error);
        });
    },

    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.submitStatus = false;
          let payload = {
            city_name: this.form.city_name,
            organization_id: this.form.organization_id,
            country_id: this.form.country_id,
            city_active: this.form.city_active,
          };

          CountryService.addCity(payload)
            .then((response) => {
              this.submitStatus = false;
              const { data } = response;
              this.$vs.loading.close();
              if (data.Status == true || data.Status == "true") {
                this.$vs.notify({
                  title: "Created!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                eventBus.$emit("refreshTableCity", data);
                this.clearForm();
              } else {
                this.$vs.notify({
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.submitStatus = true;
              console.log("error :", error);
            });
        }
      });
    },

    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.description = "";
      this.value = [];
      this.form.organization_id = localStorage.getItem("OrganizationID");
      this.$validator.reset();
    },
  },
  mounted() {
    this.getAllCountry();
  },
};
</script>
