var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{staticClass:"form-title",attrs:{"title":"Add City"}},[_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-3/4 mx-auto"},[_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"},[_c('h6',{staticClass:"mb-2 sm:mb-0"},[_vm._v("City Name*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('div',{},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full",attrs:{"name":"set_name","rules":"alpha"},model:{value:(_vm.form.city_name),callback:function ($$v) {_vm.$set(_vm.form, "city_name", $$v)},expression:"form.city_name"}})],1)])]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/5 w-full text-left sm:text-right align-self-center"},[_c('h6',[_vm._v("Country*")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('multiselect',{attrs:{"track-by":"country_id","label":"country_name","options":_vm.countries,"name":"Country","searchable":true,"allow-empty":false,"deselect-label":"Can't remove this value","clear-on-select":false,"preselect-first":true,"open-direction":"bottom"},on:{"input":function (ref) {
	var country_id = ref.country_id;

	return (this$1.form.country_id = country_id);
}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.country_name))]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('p',{staticClass:"error-msg"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Country')),expression:"errors.has('Country')"}],staticClass:"text-danger text-xs error-msg"},[_vm._v(_vm._s(_vm.errors.first("Country")))])])],1)]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/5 w-full text-left sm:text-right"},[_c('h6',{},[_vm._v("Status")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full"},[_c('b-form-checkbox',{attrs:{"value":"1","unchecked-value":"2","name":"check-button","switch":""},model:{value:(_vm.form.city_active),callback:function ($$v) {_vm.$set(_vm.form, "city_active", $$v)},expression:"form.city_active"}})],1)])])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col mx-auto"},[_c('vs-button',{staticClass:"mr-3 w-full sm:w-auto mb-3",attrs:{"disabled":_vm.submitStatus,"type":"filled"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v("\n          Submit\n        ")]),_c('vs-button',{staticClass:"w-full sm:w-auto mb-3",attrs:{"color":"warning","type":"border"},on:{"click":function($event){$event.preventDefault();return _vm.clearForm($event)}}},[_vm._v("\n          Reset\n        ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }